import React, { useContext, useState, useEffect } from 'react';
import { postData } from '../APIClient/APIClient';
import SessionContext from '../Context/SessionContext';
import { Flex, Text, Heading, Button, Checkbox } from '@radix-ui/themes';
import LoadingCourse from './LoadingCoarse';

const ProfilingInput = () => {
  const { topic, questions, responses, updateSelectedResponses, updateUserProfile } = useContext(SessionContext);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleOptionChange = (event: any) => {
    const { value, dataset } = event.target;
    if (dataset.state === "unchecked") {
      setSelectedOptions((prevSelectedOptions: string[]) => [ ...prevSelectedOptions, value ]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  const handleSubmit = () => {
    const answer = selectedOptions.join(', ');
    updateSelectedResponses({question: questions[currentQuestionIndex].question, answer: answer});
    setSelectedOptions([]);
    setCurrentQuestionIndex((prevIndex) => prevIndex+1);
  };

  useEffect(() => {
    if (currentQuestionIndex === questions.length) {
      setIsLoading(true);
      const apiUrl = 'https://api.beaconbrain.in:8001/users/1/profile';
        const dataToSend = { topic: topic, profilings: responses };

        (async () => {
        const response = await postData(apiUrl, dataToSend);
        if (response) {
            updateUserProfile(response)
            setIsLoading(false);
        }
        })();
    }
  }, [currentQuestionIndex]);

  if (!questions || questions.length === 0) {
    return <div>No questions available</div>;
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <Flex gap="4" align="center" justify="center" direction="column">
      {currentQuestionIndex === questions.length ? 
      <LoadingCourse isLoading={isLoading}/> : 
      <Flex gap="4" direction="column">
        <Heading as="h5">Answer a few questions to create a customized course for you!</Heading>
        <Text weight="bold">{currentQuestion.question}</Text>
        <div>
          {currentQuestion.options.map((option) => (
            <Flex gap="3" key={option}>
                <Checkbox id={option} value={option} onClick={handleOptionChange} checked={selectedOptions.includes(option)} onChange={handleOptionChange} />
                <Text>{option}</Text>
            </Flex>
          ))}
        </div>
        <Button disabled={selectedOptions.length === 0} onClick={handleSubmit}>
            Submit
        </Button>
      </Flex>}
    </Flex>
  );
};

export default ProfilingInput;