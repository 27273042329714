import { createContext } from 'react';
import ISessionContext from './ISessionContext';

const SessionContext = createContext<ISessionContext>({
    user: {
        id: 0
    },
    profile: {
        id: 0
    },
    topic: "",
    course: "",
    questions: [],
    responses: [],

    updateTopic: () => {},
    updateQuestions: () => {},
    updateSelectedResponses: () => {},
    updateUserProfile: () => {},
    updateCourse: () => {}
});

export default SessionContext;