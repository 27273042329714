import { Container, Flex, Heading, Skeleton, Text } from "@radix-ui/themes"
import { useContext } from "react"
import SessionContext from "../Context/SessionContext"
import ReactMarkdown from 'react-markdown';

const LoadingCourse = ({isLoading}: {isLoading: boolean}) => {
    const { topic, course } = useContext(SessionContext);
    return (
    <Container size="3">
      <Flex direction="column" gap="3">
        {isLoading ? 
        <Heading as="h5">Creating a customized course for you...</Heading> : 
        <Heading as="h5">Topic: {topic}</Heading>}
        <Text>
          <Skeleton loading={isLoading}>
            {isLoading ? 
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
            felis tellus, efficitur id convallis a, viverra eget libero. Nam magna
            erat, fringilla sed commodo sed, aliquet nec magna.` : 
            <ReactMarkdown>{course}</ReactMarkdown>} 
          </Skeleton>
        </Text>
      </Flex>
    </Container>
    )
}

export default LoadingCourse;