import React, { useState, useContext } from 'react';
import { fetchData } from '../APIClient/APIClient';
import SessionContext from '../Context/SessionContext';
import { Flex, Spinner, TextField } from '@radix-ui/themes';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';

const TopicInput = () => {
  const { topic, updateTopic, updateQuestions } = useContext(SessionContext);
  const [ isLoading, setLoading ] = useState(false);

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      setLoading(true);
      const apiUrl = `https://api.beaconbrain.in:8001/users/1/profile/questions?topic=${topic}`;
  
      (async () => {
        const data = await fetchData(apiUrl);
        if (data) {
          updateQuestions(data.questions);
        }
        setLoading(false)
      })();
    }
  };

  return (
    <Flex align="center" justify="center">
      <TextField.Root 
        size="3"
        placeholder="Enter a topic…"
        value={topic}
        onChange={(event) => updateTopic(event.target.value)}
        onKeyDown={handleKeyPress}>
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
          <TextField.Slot>
            <Spinner loading={isLoading}/>
          </TextField.Slot>
      </TextField.Root>
    </Flex>
  );
};

export default TopicInput;
