export async function fetchData(url: string) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
}
  
export async function postData<T>(url: string, data: T) {
  try {
    const response = await fetch(url, {
      method: 'POST', // Specify the HTTP method as POST
      headers: { 'Content-Type': 'application/json' }, // Set the content type header
      body: JSON.stringify(data), // Convert data to JSON string for the body
    });

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error posting data:', error);
    return null;
  }
}