import React, { useState, useContext, useEffect } from 'react';
import { postData } from '../APIClient/APIClient';
import SessionContext from '../Context/SessionContext';
import LoadingCourse from '../Components/LoadingCoarse';
import { Flex } from '@radix-ui/themes';

const CoursePage = () => {
  const { profile, updateCourse } = useContext(SessionContext);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const apiUrl = `https://api.beaconbrain.in:8001/users/1/profile/${profile.id}/course`;

    (async () => {
        const response = await postData(apiUrl, null);
        if (response) {
            updateCourse(response);
            setIsLoading(false);
        }
    })();
  }, []);

  return (
    <Flex gap="4" align="center" justify="center" direction="column">
      <LoadingCourse isLoading={isLoading}/>
    </Flex>
  );
};

export default CoursePage;
