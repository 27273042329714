import React, { useState } from 'react';
import './App.css';
import IQuestion from './Models/IQuestion';
import IResponse from './Models/IResponse';
import IProfile from './Models/IProfile';
import SessionContext from './Context/SessionContext';
import Home from './Pages/Home';
import ISession from './Models/ISession';
import { Theme } from '@radix-ui/themes';

const App = () => {
  const [session, setSession] = useState<ISession>({
    user: {
      id: 1
    },
    profile: {
      id: 0
    },
    topic:"",
    questions:[],
    responses:[],
    course:""
  });

  const updateTopic = (topic: string) => {
    setSession((prevData: ISession) => ({ ...prevData, topic: topic }));
  };

  const updateQuestions = (questions: IQuestion[]) => {
    setSession((prevData: ISession) => ({ ...prevData, questions: questions }));
  };

  const updateSelectedResponses = (response: IResponse) => {
    setSession((prevData: ISession) => ({ ...prevData, 
      responses: [ ...prevData.responses, { ...response } ]}))
  };
  
  const updateUserProfile = (profile: IProfile) => {
    setSession((prevData: ISession) => ({ ...prevData, profile: profile }))
  }

  const updateCourse = (course: string) => {
    setSession((prevData: ISession) => ({ ...prevData, course: course }))
  }

  return (
    <div>
      <SessionContext.Provider value={{ 
        ...session,
        updateTopic,
        updateQuestions,
        updateSelectedResponses, 
        updateUserProfile, 
        updateCourse }}>
          <Theme accentColor="mint"
            grayColor="gray"
            panelBackground="solid"
            scaling="100%"
            radius="full">
              <Home />
          </Theme>
      </SessionContext.Provider>
    </div>
  );
};

export default App;
