import React, { useContext } from 'react';
import SessionContext from '../Context/SessionContext';
import TopicInput from '../Components/TopicInput';
import ProfilingInput from '../Components/ProfilingInput';
import CoursePage from './Course';
import { Box, Heading, Flex } from '@radix-ui/themes';

const Home = () => {
    const { questions, profile } = useContext(SessionContext);

    return (
        <div>
            <Box>
                <Flex px="2" py="2">
                    <Heading as='h1'>Beacon Brain</Heading>
                </Flex>
                <Flex px="4" py="4" align="center" justify="center">
                    {questions.length === 0 ? <TopicInput /> :
                    (profile.id === 0 ? <ProfilingInput /> : <CoursePage />)}
                </Flex>
            </Box>
        </div>
    )
}

export default Home;